import { AuditInformation, Language } from './global';

export interface User {
  // Base user information
  id: string;
  firstName: string;
  lastName: string;
  specialty: UserSpecialty;
  role: UserRole;
  practiceNumber: string;
  userStatus: UserStatus;
  email: string;
  language?: Language;
  title?: UserTitle;
  phone?: null | string;
  address?: string;
  city?: string;
  zipCode?: null | string;
  province?: string;
  absences?: UserAbsence[];
  note?: string;
  deviceInfos?: UserDeviceInfos;

  // Billing information
  billingType?: UserBillingType;
  pools?: UserPool[];
  groups?: UserGroup[];
  groupName?: string;
  groupNumber?: string;
  agencyName?: string;
  agencyNumber?: null | string;
  agencySwitchDate?: string;
  fiscalYearStart: string;
  ramqEnvironment?: UserRamqEnvironment;
  directRamqCallEnabledPlaces?: string[];
  nextExternalReferenceNumber?: string;
  specificBillingGroups?: UserSpecificBillingGroup[];
  originalPoolNumber?: string;
  defaultBillingType?: UserDefaultBillingType;
  ramqAccreditationStatus?: UserRamqAccreditationStatus;

  // Stripe
  stripeProductId?: string;
  stripeCustomerId: string;
  stripeSubscriptionStatus?: string;
  stripeSubscriptionMetadata?: UserStripeSubscriptionMetadata;

  // Intercom
  intercomId?: string;

  // Medavie
  medavieUserName?: string;
  medaviePassword?: string;

  // RAMQ
  ramqUsername?: string;
  ramqPassword?: string;

  // Mailchimp
  mailchimpId?: string;
  mailchimpSubscriptionFailedAt?: string;

  // Feature flags
  automaticProcessingActEnabled?: boolean;
  actCodeSpecialtyListIncluded?: boolean;
  medyxRegistryEnabled?: boolean;
  paymentRequestsSentInPeriod?: boolean;
  ocrEnabled?: boolean;
  editableFavoritesEnabled?: boolean;
  editableFavoritesDoctorEnabled?: boolean;
  multipleNAMDiscoveryEnabled?: boolean;
  statementIntegration?: boolean;
  editableFavoritesDiagnosticEnabled?: boolean;
  editableFavoritesContextElementsEnabled?: boolean;
  activityRecommendationsEnabled?: boolean;
  electronicSignatureEnabled: boolean;
  mixteGenerationEnabled?: boolean;
  skipMixteGenerationForTeachingCodes?: boolean;
  duplicateAnotherDayActivities?: boolean;
  isExperimentalMemoryOptimization?: boolean;
  backendMixteEnabled?: boolean;
}

// @deprecated
export interface LegacyUser extends User {
  synchronized?: boolean;
  forceOptimization?: boolean;
  actCodeSpecialtyListChoice?: string;
  firestoreDebug?: boolean;
  subscriptionPackage?: string;
  createdOn: string | number;
  modifiedOn?: number;
  paymentType?: string;
  subscriptionDate?: number;
  ramqSendMethod?: string;
  billingAgency?: string;
  institutCardio?: boolean;
  auditInformation: AuditInformation;
  webSiteId?: number;
  referrer?: string;
  uid?: string;
  mutations?: never[];
  billingRates?: never;
  renewalDate?: number;
  primaryAgent?: string;
  secondaryAgent?: string;
  promoCode?: never;
  lastUpdated?: string;
  searchString?: string;
  additionalActInfo?: boolean;
  postalCode?: string;
}

export enum UserTitle {
  DR = 'Dr',
  DRE = 'Dre',
}

export enum UserSpecialty {
  ANESTHESIOLOGIST = 'anesthesiologist',
  ANESTHESIOLOGIST_INTENSIVIST = 'anesthesiologistIntensivist',
  CARDIOLOGIST = 'cardiologist',
  ENDOCRINOLOGIST = 'endocrinologist',
  GENERAL_SURGEON = 'generalSurgeon',
  INTERNIST = 'internist',
  ORTHOPEDIC_SURGEON = 'orthopedicSurgeon',
  OTOLARYNGOLOGIST = 'otolaryngologist',
  PNEUMOLOGIST = 'pneumologist',
}

export enum UserSpecialtyId {
  ANESTHESIOLOGIST = '03',
  ANESTHESIOLOGIST_INTENSIVIST = '03',
  CARDIOLOGIST = '06',
  ENDOCRINOLOGIST = '35',
  GENERAL_SURGEON = '07',
  INTERNIST = '18',
  ORTHOPEDIC_SURGEON = '08',
  OTOLARYNGOLOGIST = '25',
  PNEUMOLOGIST = '17',
}

export interface UserGroup {
  name: string;
  id: string;
}

export interface UserPool extends UserGroup {
  number: string;
}

export enum UserAbsenceReason {
  FELLOWSHIP = 'fellowship',
  HOLIDAYS = 'holidays',
  MATERNITY = 'maternity',
  OTHER = 'other',
  SICKNESS = 'sickness',
}

export interface UserAbsence {
  reason: UserAbsenceReason;
  comments: string;
  endDate: number;
  id: string;
  startDate: number;
}

export enum UserRamqEnvironment {
  PARTENAIRE = 'PARTE',
  PRODUCTION = 'PROD',
}

export enum UserRole {
  DOCTOR = 'doctor',
  AGENT = 'agent',
  ADMIN = 'admin',
  AUDITOR = 'auditor',
  DEVELOPER = 'developer',
}

export enum UserStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DEMO = 'demo',
  INTERNAL = 'internal',
  PAUSED = 'paused',
}

export enum UserBillingType {
  PRIVATE = 'private',
  POOL = 'pool',
}

export enum UserDefaultBillingType {
  AUTO = 'auto',
  POOL = 'pool',
  PRIVATE = 'priv',
  END = 'end',
}

export enum UserRamqAccreditationStatus {
  APPROVED = 'approved',
  MISSING = 'missing',
  PENDING_APPROVAL = 'pendingApproval',
  PENDING_SIGNATURE = 'pendingSignature',
  SIGNED = 'signed',
}

export interface UserStripeSubscriptionMetadata {
  currentPeriodStart: number;
  trialEnd: number | null;
  productId: string;
  cancelEnd: number | null;
  currentPeriodEnd: number;
  trialStart: number | null;
  start: number;
  latestEventId: string;
  productName: string;
  isCustom: boolean;
  isExpert: boolean;
  id: string;
  priceId: string;
  status: string;
}

export interface UserDeviceInfos {
  Android?: {
    model: string;
    updatedAt: number;
  };
  updatedAt: number;
  web?: {
    model: string;
    updatedAt: number;
  };
  iOS?: {
    model: string;
    updatedAt: number;
  };
}

export interface UserSpecificBillingGroup {
  id?: string;
  codes?: Array<{
    code: string;
    measurementElements: unknown[];
    units: Array<{
      role: string;
      units: string;
    }>;
    natureType: string;
  }>;
  number: string;
  types?: string[];
  name: string;
  groupBillingTypes?: UserBillingType[];
  doctors?: Array<{
    firstName: string;
    lastName: string;
    specialty?: string;
    practiceNumber: string;
    isManager: number;
    id: string;
  }>;
}

export interface UserAccountant {
  id: string;
  fullName: string;
  email: string;
}
