import clone from 'lodash/clone';
import { put } from 'redux-saga/effects';
import { upload } from '../../../../../containers/Storage/sagas';
import { ActivityAttachmentType } from 'shared/medyx-core/types/activity';
import { saveAct } from '../../../../../containers/Act/actions';
import { DOCUMENT_ID } from '../../fileUpload/sagas/fileUploadStart';
import { getUserActStorageReference } from '../../../../../firebase/storage/storageRepository';

export default function* uploadFileAndSaveTasksForAct(act, userId, picture) {
  let actToSave = clone(act);

  if (picture) {
    const uploadTask = createActSpecificUploadTask(act.id, userId, picture.file);
    const downloadUrl = yield* upload({ uploadTask });

    actToSave = {
      ...act,
      attachments: [
        ...(act.attachments ?? []),
        {
          type: ActivityAttachmentType.CARD_PICTURE,
          location: downloadUrl,
          fileName: uploadTask.fileName,
          temporary: false
        }
      ]
    };
  }

  return yield put(saveAct(actToSave));
}

function createActSpecificUploadTask(actId, userId, file) {
  const actSpecificFile = clone(file);

  const regex = new RegExp(DOCUMENT_ID, 'gi');

  actSpecificFile.actId = actSpecificFile.actId.replace(regex, actId);
  actSpecificFile.id = actSpecificFile.id.replace(regex, actId);
  actSpecificFile.fileName = actSpecificFile.fileName.replace(regex, actId);

  actSpecificFile.storageReference = getUserActStorageReference(userId, actId, actSpecificFile.fileName);

  return actSpecificFile;
}
