import { createSelector } from '@reduxjs/toolkit';
import { formValueSelector } from 'redux-form';
import { ACT_FORM_NAME } from './constants';
import { ActivityAttachmentType } from 'shared/medyx-core/types/activity';

const selectActForm = (state) => state.actForm;

export const selectReduxActForm = (state) => {
  const stateForm = state.form;

  return stateForm[ACT_FORM_NAME]?.values ?? {};
};

export const selectReduxActFormObject = (state) => selectReduxActForm(state);

export const selectCurrentActForm = () => createSelector(selectReduxActForm, (reduxFormState) => reduxFormState ?? {});

export const selectNamSelectorDialogState = () =>
  createSelector(selectActForm, (actFormState) => actFormState.namSelectorDialog);

export const selectSelectedDate = () => createSelector(selectReduxActForm, (reduxFormState) => reduxFormState.date);

export const selectSelectedNam = () => createSelector(selectReduxActForm, (reduxFormState) => reduxFormState.nam);

export const selectExtractedNams = () =>
  createSelector(selectReduxActForm, (reduxFormState) => reduxFormState.extractedNams ?? []);

export const selectNam = () => (state) => formValueSelector(ACT_FORM_NAME)(state, 'nam');

export const selectPatientEvent = () => (state) => formValueSelector(ACT_FORM_NAME)(state, 'patientEvent');

export const selectRamqPatient = () => (state) => formValueSelector(ACT_FORM_NAME)(state, 'ramqPatient');

export const selectRamqPatientNamIsAdmissible = () => (state) => selectRamqPatient()(state)?.isAdmissible;

export const selectBillingType = () => (state) => formValueSelector(ACT_FORM_NAME)(state, 'billingType');

export const getSelectedPlace = () => (state) => {
  const place = formValueSelector(ACT_FORM_NAME)(state, 'place');
  return place ?? {};
};

export const getSavedPicture = () => (state) => {
  /**
   * This weird business logic is made to handle the fact that the assets was stored in the assets key and from now on it will be
   * stored in the attachments key. In other words, the assets key will be removed in the future and the attachments key will be
   * used to store the assets. So in order to backward compatible, we need to check if the assets key is present and if it is,
   * we will use it to get the "castonguette".
   */
  const assets = formValueSelector(ACT_FORM_NAME)(state, 'assets');
  if (assets && assets.length > 0) {
    return assets[0];
  }

  const attachments = formValueSelector(ACT_FORM_NAME)(state, 'attachments');
  if (attachments && attachments.length > 0) {
    return attachments.find(({ type }) => type === ActivityAttachmentType.CARD_PICTURE);
  }

  return undefined;
};

export const selectCurrentCodes = () =>
  createSelector(selectReduxActForm, (reduxFormState) => {
    if (!reduxFormState) {
      return [];
    }

    const { codes = [] } = reduxFormState;

    return codes;
  });

export const selectSelectedActPlace = () =>
  createSelector(selectReduxActForm, (reduxFormState) => reduxFormState?.place ?? {});

export const selectEndTime = () => createSelector(selectReduxActForm, (reduxFormState) => reduxFormState.end);

export const selectStartTime = () => (state) => {
  const start = formValueSelector(ACT_FORM_NAME)(state, 'start');

  if (start === '') {
    return NaN;
  }

  return start;
};

export const selectRempTime = () => (state) => {
  const remp = formValueSelector(ACT_FORM_NAME)(state, 'remp');

  if (remp === '') {
    return NaN;
  }

  return remp;
};

export const selectSavedPicture = () =>
  createSelector(selectReduxActForm, (reduxFormState) => {
    /**
     * This weird business logic is made to handle the fact that the assets was stored in the assets key and from now on it will be
     * stored in the attachments key. In other words, the assets key will be removed in the future and the attachments key will be
     * used to store the assets. So in order to backward compatible, we need to check if the assets key is present and if it is,
     * we will use it to get the "castonguette".
     */
    if (reduxFormState?.assets && reduxFormState?.assets.length > 0) {
      return reduxFormState.assets[0];
    }

    if (reduxFormState?.attachments && reduxFormState?.attachments.length > 0) {
      return reduxFormState.attachments.find(({ type }) => type === ActivityAttachmentType.CARD_PICTURE)[0];
    }
  });

export const selectAssets = () => createSelector(selectReduxActForm, (reduxFormState) => reduxFormState?.assets ?? []);

export const selectAttachments = () =>
  createSelector(selectReduxActForm, (reduxFormState) => reduxFormState?.attachments ?? []);

export const selectActivityArea = () =>
  createSelector(selectReduxActForm, (reduxFormState) => reduxFormState.activityArea);

export const selectPatientType = () =>
  createSelector(selectReduxActForm, (reduxFormState) => reduxFormState?.patientInformation?.type);

export const selectDisclaimerAccepted = () =>
  createSelector(
    selectReduxActForm,
    (reduxFormState) => reduxFormState?.patientInformation?.urgent?.disclaimerAccepted
  );

export const selectOutsideRamqPatientType = () =>
  createSelector(selectReduxActForm, (reduxFormState) => reduxFormState?.patientInformation?.outsideRamq?.patientType);

export const selectPatientCarence = () =>
  createSelector(selectReduxActForm, (reduxFormState) => reduxFormState?.patientInformation?.carence);

export const selectPatientContext = () =>
  createSelector(selectReduxActForm, (reduxFormState) => reduxFormState.patientContext ?? {});

export const selectPatientDiagnostic = () => createSelector(selectPatientContext(), (state) => state.diagnostic);

export const selectPatientInformation = () =>
  createSelector(selectReduxActForm, (reduxFormState) => reduxFormState?.patientInformation ?? {});

export const selectUrgentCallDetails = () =>
  createSelector(selectReduxActForm, (reduxFormState) => reduxFormState?.urgentCallDetails);

export const selectUrgentCallDate = () =>
  createSelector(selectReduxActForm, (reduxFormState) => {
    const urgentCallDetails = reduxFormState && reduxFormState.urgentCallDetails;
    const urgentCallDate = urgentCallDetails ? urgentCallDetails.date : undefined;

    return urgentCallDate ?? formValueSelector(ACT_FORM_NAME)(reduxFormState, 'date');
  });

export const selectContextElements = () =>
  createSelector(selectReduxActForm, (reduxFormState) => reduxFormState?.contextElements ?? []);

export const selectPicturePreviewDisplayed = () =>
  createSelector(selectActForm, (actState) => actState.picturePreviewDisplayed);

export const selectActs24HoursBefore = () => createSelector(selectActForm, (actState) => actState.acts24HoursBefore);

export const selectActs24HoursLoaded = () => createSelector(selectActForm, (actState) => actState.acts24HoursLoaded);

export const selectActId = () => createSelector(selectReduxActForm, (reduxFormState) => reduxFormState?.id);

export const selectActDate = () => createSelector(selectReduxActForm, (reduxFormState) => reduxFormState?.date);
