import momentTimezone from 'moment-timezone';
import { DateRange, extendMoment } from 'moment-range';

import { selectActivitiesInContext } from 'app/containers/Activities/selectors';
import { selectSpecialty } from 'app/containers/User/selectors';
import { getStore } from 'app/reduxStore/configureStore';
import { UserSpecialty } from 'shared/medyx-core/types/user';
import Act from 'shared/domain/activity/act/model/Act';
import LumpSum from 'shared/domain/activity/lumpSum/model/LumpSum';
import ActivityType from 'shared/core/activity/domain/ActivityType';
import ActivityStatus from 'shared/core/activity/domain/ActivityStatus';

const moment = extendMoment(momentTimezone as any);

export const NO_WARNINGS = [];
export const WARNING_MESSAGE =
  "Vous avez déjà un forfaitaire de facturé aux mêmes heures, le chevauchement n'est pas permis.";

const OVERLAPPING_LUMP_SUM_WARNING_PER_SPECIALTY: Record<UserSpecialty, boolean> = {
  [UserSpecialty.ANESTHESIOLOGIST]: true,
  [UserSpecialty.ANESTHESIOLOGIST_INTENSIVIST]: true,
  [UserSpecialty.CARDIOLOGIST]: false,
  [UserSpecialty.ENDOCRINOLOGIST]: false,
  [UserSpecialty.GENERAL_SURGEON]: false,
  [UserSpecialty.INTERNIST]: false,
  [UserSpecialty.ORTHOPEDIC_SURGEON]: false,
  [UserSpecialty.OTOLARYNGOLOGIST]: false,
  [UserSpecialty.PNEUMOLOGIST]: false
};

const overlappingLumpSumWarning = (act: Partial<Act>): string[] => {
  const userSpecialty = selectSpecialty()(getStore().getState());

  if (!OVERLAPPING_LUMP_SUM_WARNING_PER_SPECIALTY[userSpecialty]) return NO_WARNINGS;

  const startTime = act.remp ?? act.start;

  if (!startTime) return NO_WARNINGS;

  const lumpSumps = getActiveLumpSumpsInContext();

  if (!lumpSumps.length) return NO_WARNINGS;

  const actStartDate = moment(act.remp ?? act.start);
  const actEndDate = act.end ? moment(act.end).subtract(1, 'millisecond') : actStartDate.clone();
  const actDateRange = moment.range(actStartDate, actEndDate);

  if (!isDateRangeOverlapsSomeLumpSums(actDateRange, lumpSumps)) return NO_WARNINGS;

  return [WARNING_MESSAGE];
};

const getActiveLumpSumpsInContext = (): LumpSum[] => {
  const activitiesInContext = selectActivitiesInContext()(getStore().getState()) ?? [];

  return activitiesInContext.filter(
    ({ status, type }) => type === ActivityType.LUMP_SUM && status !== ActivityStatus.CANCELED
  );
};

const isDateRangeOverlapsSomeLumpSums = (dateRange: DateRange, lumpSumps: LumpSum[]): boolean =>
  lumpSumps.some(({ days }) =>
    days.some((day) => {
      const lumpSumDayTimeRange = moment.range(moment(day.start), moment(day.end).subtract(1, 'millisecond'));

      return dateRange.overlaps(lumpSumDayTimeRange, { adjacent: true });
    })
  );

export default overlappingLumpSumWarning;
