import moment from 'moment-timezone';
import flatMap from 'lodash/flatMap';
import ActivityStatus from '../../../../shared/core/activity/domain/ActivityStatus';
import { activitiesCollectionRef } from '../../../firebase/collection/collectionReferences';
import { ACTIVITIES_TRAINING_TYPE } from '../../../../shared/collection/collectionConstants';

const JANUARY = 0;
const MAY = 4;
const APRIL = 3;

export default async function calculateHalfDayTrainingClaimingForYearToDay(userId, trainingDate) {
  const dateInReference = moment(trainingDate);

  if (isBetweenFirstDayOfTheYearAndLastDayOfApril(dateInReference)) {
    dateInReference.subtract(1, 'year');
  }

  const trainings = await getAllTrainingActivityByUser(userId, {
    start: dateInReference.clone().month(MAY).startOf('month'),
    end: dateInReference.clone().month(APRIL).endOf('month').add(1, 'year')
  });

  const trainingsNotCanceled = trainings.filter(isActivityNotCanceled);

  return getAllTrainingDaysFrom(trainingsNotCanceled).map(numberOfPerdiemsPerformedThisDay).reduce(summarize, 0);
}

async function getAllTrainingActivityByUser(userId, dateRange) {
  const { docs } = await activitiesCollectionRef()
    .where('type', '==', ACTIVITIES_TRAINING_TYPE)
    .where('userId', '==', userId)
    .where('date', '>=', dateRange.start.valueOf())
    .where('date', '<=', dateRange.end.valueOf())
    .get();

  return docs.map((doc) => doc.data());
}

function getAllTrainingDaysFrom(trainings) {
  return flatMap(trainings.map((doc) => doc.days));
}

function isActivityNotCanceled(training) {
  return training.status !== ActivityStatus.CANCELED;
}

function numberOfPerdiemsPerformedThisDay(day) {
  return day.perdiems.length;
}

function summarize(accumulator, currentValue) {
  return accumulator + currentValue;
}

function isBetweenFirstDayOfTheYearAndLastDayOfApril(date) {
  return date.isBetween(
    date.clone().month(JANUARY).startOf('month'),
    date.clone().month(APRIL).endOf('month'),
    'milliseconds', // Granularity
    '[]' // Inclusive
  );
}
