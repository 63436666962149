import { call, put, select, takeEvery } from 'redux-saga/effects';
import actAttachmentRepository from '../Storage/cordova/actAttachmentRepository';
import ActFile from '../Storage/cordova/ActFile';
import { ACTIVITIES_TRAVEL_EXPENSES_TYPE } from '../../../shared/collection/collectionConstants';
import { getStore } from '../../reduxStore/configureStore';
import {
  operateActivitiesWithUserFilter,
  setDocument,
  trackErrorInFirestore,
  updateDocument
} from '../../firebase/document/documentSagas';
import {
  getUpdatedAttachments,
  removeTemporaryFileFromDeviceLocalStorage,
  updateAttachmentInPersistedAct
} from '../ActForm/sagas';
import { SYNCHRONIZED_ATTACHMENT } from '../Storage/cordova/constants';
import {
  SAVE_TRAVEL_EXPENSES,
  saveTravelExpensesFailure,
  TRAVEL_EXPENSE_FILE_READY_FOR_UPLOAD,
  UPDATE_TRAVEL_EXPENSES,
  updateSavedAttachmentInTravelExpensesForm,
  updateTravelExpensesFailure
} from './actions';
import { TRAVEL_EXPENSE_ATTACHMENT } from './constants';
import { logDefault, logError } from '../../../shared/utils/plog';
import { selectActId, selectAttachments } from './selectors';
import { selectUserIdInContext } from '../User/selectors';
import { UPLOAD_ERROR, UPLOAD_SUCCESS } from '../Storage/actions';
import storageUploader from '../Storage/uploader/StorageUploader';
import StorageUploadContext from '../Storage/uploader/StorageUploadContext';
import { ActivityAttachmentType } from 'shared/medyx-core/types/activity';

export function* travelExpenseFileReadyForUpload(action) {
  logDefault({
    type: 'saga',
    text: 'TravelExpensesSagas/travelExpenseFileReadyForUpload',
    array: ['Starting travelExpenseFileReadyForUpload. action is', action]
  });

  try {
    const { attachmentType, documentId, fileEntry, persistFileLocallyBeforeUpload } = action;
    const actAttachment = new ActFile(documentId, fileEntry);

    const uploadContext = new StorageUploadContext(documentId, fileEntry, persistFileLocallyBeforeUpload);
    uploadContext.fileWrapper = actAttachment;
    uploadContext.uploadType = attachmentType;
    uploadContext.userId = yield select(selectUserIdInContext());
    uploadContext.localFileRepository = actAttachmentRepository;
    uploadContext.postLocalPersistFileHook = postLocalPersistAttachment;
    uploadContext.storeDispatch = getStore().dispatch;

    storageUploader.initiateUpload(uploadContext);
  } catch (e) {
    logError({
      type: 'saga',
      text: 'TravelExpensesSagas/travelExpenseFileReadyForUpload',
      array: ['travelExpenseFileReadyForUpload ERROR', action, e]
    });
    yield* trackErrorInFirestore(e, { type: 'travelExpenseFileReadyForUploadError' });
  }
}

export function postLocalPersistAttachment(actId, addedAttachment, localPath) {
  const currentAttachments = selectAttachments()(getStore().getState());
  const updatedAttachments = [
    ...currentAttachments,
    {
      type: ActivityAttachmentType.CARD_ATTACHMENT,
      location: localPath,
      fileName: addedAttachment.fileName,
      originalFileName: addedAttachment.originalFileName,
      temporary: true
    }
  ];

  logDefault({
    type: 'saga',
    text: 'TravelExpensesSagas/postLocalPersistAttachment',
    array: ['Dispatching updateSavedAttachmentInTravelExpensesForm action with attachment', updatedAttachments]
  });
  getStore().dispatch(updateSavedAttachmentInTravelExpensesForm(updatedAttachments));
}

export function* travelExpenseFileSuccessfullyUploaded(action) {
  const { downloadUrl, uploadTask } = action;
  const currentFormActId = yield select(selectActId());
  logDefault({
    type: 'saga',
    text: 'TravelExpensesSagas/travelExpenseFileSuccessfullyUploaded',
    array: [
      'Starting travelExpenseFileSuccessfullyUploaded with action',
      action,
      'and currentFormActId',
      currentFormActId
    ]
  });

  try {
    if (currentFormActId === uploadTask.actId) {
      // Whatever the type, if the actId matches, let's update the form. The technicality is that upon network or app resume, any
      // pending attachment files to be uploaded will be processed with the upload task type "synchronizedAttachment", but it
      // might still concern this saga. In such case, the ActForm's saga will take care of updating the persisted act.
      const attachments = yield select(selectAttachments());
      const updatedAttachments = getUpdatedAttachments(attachments, uploadTask, downloadUrl);

      logDefault({
        type: 'saga',
        text: 'TravelExpensesSagas/travelExpenseFileSuccessfullyUploaded',
        array: ['Updating attachment in travel expense form']
      });
      yield put(updateSavedAttachmentInTravelExpensesForm(updatedAttachments));
    }

    if (uploadTask.type === TRAVEL_EXPENSE_ATTACHMENT) {
      // In all cases, the updateAttachmentInPersistedAct method must be called. For example, the user previously persisted an
      // act with a local file path while offline. Then, he reopens the act and goes online. In this case, upon upload success,
      // the file URL must be updated in both the form and the persisted act (in case he does not update the form). If the
      // attachment hasn't been saved yet, then the update method should just do nothing.
      logDefault({
        type: 'saga',
        text: 'TravelExpensesSagas/travelExpenseFileSuccessfullyUploaded',
        array: ['Updating attachment in persisted travel expense']
      });
      yield* updateAttachmentInPersistedAct(uploadTask.actId, downloadUrl);

      // All act types attachments are in fact stored in the same local folder, so that's why I'm not using the
      // TRAVEL_EXPENSE_ATTACHMENT type here
      yield* removeTemporaryFileFromDeviceLocalStorage(uploadTask.fileName, SYNCHRONIZED_ATTACHMENT);
    } else {
      logDefault({
        type: 'saga',
        text: 'TravelExpensesSagas/travelExpenseFileSuccessfullyUploaded',
        array: ['Upload task type not known to this saga. Skipping any processing.', uploadTask.type]
      });
    }
  } catch (e) {
    logError({
      type: 'saga',
      text: 'TravelExpensesSagas/travelExpenseFileSuccessfullyUploaded',
      array: ['travelExpenseFileSuccessfullyUploaded ERROR', uploadTask, downloadUrl, e]
    });
    yield* trackErrorInFirestore(e, { type: 'TravelExpenseFileSuccessfullyUploadedError' });
  } finally {
    if (uploadTask.type === TRAVEL_EXPENSE_ATTACHMENT) {
      storageUploader.completeUploadProcessForFile(uploadTask.fileName);
    }
  }
}

export function* travelExpenseFileUploadError(action) {
  const { uploadTask } = action;

  yield call(storageUploader.completeUploadProcessForFile, uploadTask.fileName);
}

export default function* travelExpensesSaga() {
  yield takeEvery(TRAVEL_EXPENSE_FILE_READY_FOR_UPLOAD, travelExpenseFileReadyForUpload);
  yield takeEvery(UPLOAD_ERROR, travelExpenseFileUploadError);
  yield takeEvery(isActionUploadSuccessForTravelExpensesAttachment, travelExpenseFileSuccessfullyUploaded);
  yield takeEvery(
    SAVE_TRAVEL_EXPENSES,
    operateActivitiesWithUserFilter,
    ACTIVITIES_TRAVEL_EXPENSES_TYPE,
    setDocument,
    saveTravelExpensesFailure
  );
  yield takeEvery(
    UPDATE_TRAVEL_EXPENSES,
    operateActivitiesWithUserFilter,
    ACTIVITIES_TRAVEL_EXPENSES_TYPE,
    updateDocument,
    updateTravelExpensesFailure
  );
}

export const isActionUploadSuccessForTravelExpensesAttachment = (action) =>
  action.type === UPLOAD_SUCCESS && action.uploadTask.type === TRAVEL_EXPENSE_ATTACHMENT;
